import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Grid } from '@sumup/circuit-ui/legacy';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import SectionHeader from '~/shared/components/SectionHeader';
import PricingCards from '~/shared/components/PricingCards';
import CardSchemes from '~/shared/components/CardSchemes';
import formatSelector from '~/shared/util/data-selector';

const DATA_SELECTOR_ID = 'pricing_cards_with_card_schemes';

const pricingCardsStyles = ({ theme }) => css`
  margin-bottom: ${theme.spacings.exa};
`;

const StyledPricingCards = styled(PricingCards)(pricingCardsStyles);

const PricingCardsWithCardSchemes = ({
  className,
  headline,
  description,
  pricingCards,
  cardSchemes,
}) => (
  <Grid
    data-selector={formatSelector('section', DATA_SELECTOR_ID)}
    className={className}
    data-elbcontext="component:pricing_cards_with_card_schemes"
  >
    <Row>
      <Col span={{ default: 12 }}>
        <SectionHeader headline={headline} content={description} />
        <StyledPricingCards {...pricingCards} />
        <CardSchemes cardSchemes={cardSchemes} />
      </Col>
    </Row>
  </Grid>
);

PricingCardsWithCardSchemes.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.object,
  pricingCards: PropTypes.object,
  cardSchemes: PropTypes.arrayOf(PropTypes.string),
};

/**
 * @component
 */
export default PricingCardsWithCardSchemes;
